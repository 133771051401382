<template>
  <div
    class="inputText"
    :class="[
      width,
      requiredMessage || matchesMessage
        ? 'inputTextWithErrMsgData'
        : 'inputTextWithoutErrMsgData',
    ]"
  >
    <FormKit
      :id="id"
      v-model="value"
      type="text"
      :placeholder="t(placeholder)"
      :name="name ?? id"
      :input-class="{
        input: true,
        [inputClass]: inputClass,
        'bg-background-base': disabled && hasWhiteBg,
        '!rounded-full': rounded,
      }"
      :outer-class="'mb-sm'"
      :validation-visibility="validationVisibility"
      :validation-messages="{
        required: t(requiredMessage),
        matches: t(matchesMessage),
      }"
      :validation="validationRules"
      label-class="text-md !leading-[19px] font-bold"
      :inner-class="{
        'pt-[5px] relative': true,
        'opacity-50': disabled,
        [innerClass]: innerClass,
      }"
      :messages-class="
        rounded
          ? 'hidden'
          : '!bg-background-base px-sm py-[2px] text-status-danger-base'
      "
      :disabled="disabled"
      :maxlength="maxlength"
      :auto-focus="autofocus"
      :data-testid="testid.length > 0 ? testid : name"
      @blur="$emit('blur')"
      @node="(n) => (node = n)"
    >
      <template #label>
        <FormLabel
          v-if="!$slots.label && label"
          :label="label"
          :required="required"
        />
        <slot v-if="$slots.label" name="label" />
      </template>
      <template v-if="info && !disabled" #suffix>
        <Popup trigger-class="top-[15px] right-[15px] ">
          <template #trigger>
            <FaIcon
              icon-class="fad fa-circle-info"
              style="
                --fa-primary-color: var(--thm-white-base);
                --fa-secondary-color: var(--thm-status-warn-base);
                --fa-secondary-opacity: 1;
              "
            />
          </template>
          <template #content>
            {{ t(info) }}
          </template>
        </Popup>
      </template>
    </FormKit>
  </div>
</template>
<script setup lang="ts">
import { FormLabel } from '@/complib';
import Popup from '~~/src/components/formFields/components/formInfoPopup.vue';
import FaIcon from '~~/src/components/fa-icon.vue';
import { FormKit } from '@formkit/vue';
import type { FormKitNode } from '@formkit/core';
import { type FormkitFieldProps } from '~/@types/formie';

const props = withDefaults(defineProps<FormkitFieldProps>(), {
  label: '',
  id: undefined,
  required: true,
  placeholder: '',
  name: '',
  width: 'w-full',
  requiredMessage: '',
  matchesMessage: '',
  validation: () => [] as [rule: string, ...args: any[]][],
  disabled: false,
  maxlength: 255,
  inputClass: '',
  innerClass: '',
  info: '',
  validationVisibility: 'blur',
  hasWhiteBg: true,
  rounded: false,
  parentNode: null,
  testid: '',
  autofocus: false,
});

defineEmits<{
  (e: 'blur'): void;
}>();

const value = defineModel<string>({
  default: '',
});

onMounted(() => {
  const input: HTMLElement = document.querySelector(
    `input[name='${props.name}']`,
  );
  if (props.autofocus) {
    input.focus();
  }
});

const validationRules = computed<[rule: string, ...args: any[]][]>(() => {
  const validations = [];
  if (props.required) validations.push(['required']);
  if (props.validation && typeof props.validation !== 'string')
    validations.push(...props.validation);
  if (props.validation && typeof props.validation === 'string')
    validations.push([props.validation]);
  return validations as [rule: string, ...args: any[]][];
});

const { t } = useTrans();

const node = ref<FormKitNode | null>(null);
</script>
<style lang="postcss" scoped>
.inputText :deep(input.input) {
  @apply border-l-1 border-r-1 border-t-1 outline-0 border-b-[3px] h-9 px-2 md:px-4 pt-2 text-md text-text-base ring-0 focus:border-b-[3px] shadow-none z-100;
  @apply focus:border-b-primary-light rounded-alt-sm border-border-light;
  @apply w-full;
  &:placeholder-shown {
    @apply overflow-ellipsis overflow-hidden;
  }
}

.inputTextWithErrMsgData :deep([data-invalid='true']) {
  input.input {
    @apply !border-b-status-danger-base rounded-br-none rounded-bl-none;
  }
}

.inputTextWithoutErrMsgData :deep([data-invalid='true']) {
  input.input {
    @apply !border-b-status-danger-base;
  }
}
</style>
